const educationDetails = [
{
    yearRange: "2012 - 2015",
    title: "Bachelor in Computer Engineering",
    place: "Algebra University",
    desc: "Multimedia Engineering",
  }, {
    yearRange: "2010 - 2012",
    title: "",
    place: "Faculty of organization and informatics",
    desc: "Computer Engineering",
  }, {
    yearRange: "2006 - 2010",
    title: "",
    place: "Gymnasium Velika Gorica",
    desc: "",
  },
];

const experienceDetails = [
  {
    yearRange: "2022 — 2024",
    title: "Frontend Engineer",
    place: "Productive",
    desc: "Employed at a leading Software as a Service (SaaS) organization where I was responsible for the design, delivery and maintenance of the text editing module over which I had ownership. I also did improvements together with test automation and maintenance of realtime communication eco-systems and that was the most challenging tasks in my career. As technical advances in this position, I would highlight the establishment of an end-to-end (E2E) testing environment for an Ember.js web application, employing Puppeteer and Jest. In addition to daily meetings with the team as well as the development itself, my responsibility was onboarding and mentoring new team members. This experience afforded me the opportunity to contribute significantly to the growth and success of the team, showcasing my adeptness in project management and collaborative leadership.",
  }, {
    yearRange: "2017 — 2022",
    title: "Frontend Engineer & Team Lead",
    place: "Uniqcast",
    desc: "Working in SaaS company where I was responsible for design, development and delivery web client applications ( administration and IPTV interface ) developed with Angular ( 5+ ). In this company I progressed from junior to mid / senior frontend engineer and I successfully designed, published and maintained 3 client applications. Of these, two are administrative interfaces and one is an application for viewing / streaming IPTV content. I contributed to establishing an end-to-end (E2E) testing environment for web applications, using the Protractor testing framework.",
  }, {
    yearRange: "2015 - 2017",
    title: "Frontend Engineer",
    place: "Q",
    desc: "Part of a team on large projects for Facebook and Coca Cola, but also capable of delivering the whole project himself. Most of the websites were created in the Wordpress CMS platform. During these projects, I learned how to create custom themes and plugins from scratch that were adapted to the client's needs.",
  }, {
    yearRange: "2014 - 2015",
    title: "Full Stack Engineer",
    place: "IDE3",
    desc: "Successfully led the developers and web designer team and delivered 3 projects - two web sites and a survey application for Mega Productions Ltd. The application is being used by a local radio station “Antena Zagreb” which was developed as a responsive web application in a PHP scripting language.",
  },
];

const skills = [
  {
    name: "HTML/CSS",
    percent: 100,
  }, {
    name: "JavaScript",
    percent: 100,
  }, {
    name: "React",
    percent: 80,
  }, {
    name: "Angular",
    percent: 100,
  }, {
    name: "Ember JS",
    percent: 100,
  }, {
    name: "Vue",
    percent: 80,
  }, {
    name: "Node.js",
    percent: 80,
  }, {
    name: "PHP",
    percent: 80,
  }, {
    name: "Wordpress CMS",
    percent: 100,
  }, {
    name: "Magento CMS",
    percent: 100,
  }, {
    name: "Craft CMS",
    percent: 100,
  }, {
    name: "Cross-platform mobile development",
    percent: 90,
  }, {
    name: "Orchestration and virtualization",
    percent: 70,
  }, {
    name: "CI/CD",
    percent: 70,
  }, {
    name: "Shell scripting",
    percent: 70,
  },
];

export { educationDetails, experienceDetails, skills }
