export const services = [
  {
    name: "Web Development",
    desc: "From custom web projects using Wordpress & Craft CMS to business applications driven by Angular and React.",
    icon: "fas fa-laptop",
  },
  {
    name: "Mobile Development",
    desc: "Employing cross-platform technologies, we proficiently develop tailored mobile applications to precisely meet the distinctive requirements of our clients.",
    icon: "fas fa-mobile",
  },
  {
    name: "UI/UX Design",
    desc: "Our designer partners are in charge of making sure that every pixel is adapted to the client's needs.",
    icon: "fas fa-pencil-ruler",
  },
  {
    name: "Consulting Services",
    desc: "Support you in the conception of a digitalization strategy, in the analysis of processes, in the collection of requirements through to IT project management during the implementation of the software project.",
    icon: "fas fa-comments",
  },
  {
    name: "Business Analysis",
    desc: "Our Business Analysis service employs rigorous methodologies to evaluate, optimize, and streamline organizational processes, fostering informed decision-making and sustainable business growth.",
    icon: "fas fa-chart-area",
  },
  {
    name: "SEO Marketing",
    desc: "Strategically enhances online visibility, drives organic traffic, and maximizes search engine rankings to propel businesses towards heightened digital prominence and success.",
    icon: "fas fa-bullhorn",
  },
];
